@use "@able/web/src/index" as able;

// Variables
$focusedForeground: #0064d2;
$focusedBorder: #b2e0fd;

// Table styles
.service-table {
    width: 100%;
    border-collapse: collapse;
    color: rgb(65, 65, 65);

    table {
        width: 100%;
        border-spacing: 0;
        text-align: left;
    }

    thead {
        background-color: hsl(0, 0%, 95%);
    }

    tbody {
        font-family: TelstraAkkuratLight;
        font-size: small;
    }

    td {
        height: 64px;
        width: 173px;
        padding: 1rem 0.5em;
        border: 1px solid rgb(216, 216, 216);
        text-align: left;
    }

    tr:first-child th {
        background-color: (230, 230, 230);
        height: 56px;
        width: 172px;
        padding: 1rem 0.5em;
        border: 1px solid white;
        text-align: left;
    }

    tr th:first-child {
        border-left: 1px solid rgb(216, 216, 216);
    }

    tr th:last-child {
        border-right: 1px solid rgb(216, 216, 216);
    }

    tbody>tr {
        position: relative;

        &:hover {
            background-color: rgb(216, 239, 254);
            // box-shadow: 0 0 0.25rem #0064d2 inset;
        }

        &:focus-within {
            box-shadow: 0 0 0 0.1rem $focusedForeground inset, 0 0 0 0.25rem $focusedBorder inset;
        }
    }

    tr:nth-child(even) {
        background-color: rgb(250, 250, 250);
    }
}

.row-link {
    padding: 0.5rem;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        cursor: pointer;
    }
}

.service-table-container {
    overflow-x: auto;
}

.service-table-footer {
    min-height: 50px;
    margin-bottom: 2em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1em;
    padding: 0rem 0.5em;
    border: 1px solid black;
}

.item-count-select-container {
    margin-left: 1rem;

    select {
        margin: 1rem;
        padding: 0.25rem;
        border-radius: 0.25rem;

        &:focus {
            box-shadow: 0 0 0 0.1rem $focusedForeground, 0 0 0 0.25rem $focusedBorder;
            outline: none;
        }
    }
}

// Pagination styles
.pagination-nav {
    display: flex;
    align-items: center;
    gap: 0.2rem;

    @media (min-width: 1048px) {
        margin-left: auto;
    }
}

.pagination-nav .button-page-select {
    background: none;
    border: 1px solid grey;
    border-radius: 0.4rem;
    padding: 0.5rem 0.75rem;

    &:focus {
        box-shadow: 0 0 0 0.1rem $focusedForeground, 0 0 0 0.25rem $focusedBorder;
        outline: none;
    }
}

.button-page-active {
    background-color: #0064d2 !important;
    color: #ffffff;
    font-weight: bold;
}