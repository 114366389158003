.throughput-content-detail {
    display: flex;
    flex-direction: row;
    margin-top: 46px;
    justify-content: flex-start;
}

.left-content {
    margin-bottom: 40px;
}

.justify-content {
    text-justify: inter-word !important;
    list-style-type: disc;
    padding-left: 2.5em;
    font-family: "TelstraAkkuratLight";
    font-weight: 300;
    font-size: 14px;
    li {
        line-height: 1.5;
    }
}

.justify-content-disclaimer {
    text-justify: inter-word !important;
    padding-left: 1.5em;
    font-family: "TelstraAkkuratLight";
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 2.5em;
}

.info {
    margin-top: 35px;
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
}

.picto-sizing {
    width: 104px;
    height: 104px;
}
