.card {
    width: 328px;
    height: 590px;
    padding: 16px 23px 16px 23px;
    border-radius: 2px;
    border: 1px solid rgb(210, 210, 210);
    margin-bottom: 39px;
    min-width: 277px;
}

.card-upper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.upper-content-flex {
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
}

.card-header {
    display: flex;
    align-items: center;
}

.header-txt {
    color: rgb(40, 40, 40);
    font-family: "TelstraAkkuratBold";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 44px;
    margin: 0;
}

.action-btn {
    color: rgb(0, 100, 210);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.3px;
    line-height: 19px;
    min-width: 159px;
}

.service-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "TelstraAkkurat";
    color: rgb(65, 65, 65);
    font-size: 16px;
    letter-spacing: 0.15px;
    min-width: 269px;
    border-bottom: 1px solid lightgray;
    height: 30px;
    padding: 0 1.25em 0.75em 0.25em;
    text-decoration: none;
}

.services-active-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "TelstraAkkurat";
    color: rgb(65, 65, 65);
    font-size: 16px;
    letter-spacing: 0.15px;
    min-width: 269px;
    border-bottom: 1px solid lightgray;
    height: 30px;
    padding: 0 1.25em 0.75em 0.25em;
    text-decoration: none;
}

.timestamp-txt {
    color: rgb(65, 65, 65);
    font-size: 16px;
    font-family: TelstraAkkurat-Regular;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 20px;
    margin: 0;
}

.sub-header-txt {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 30px;
}

.loader-center {
    margin-top: 50%;
    text-align: center;
}

.total-services-header {
    align-items: center;
    justify-content: space-between;
    text-align: center;
    min-height: 19.5px;
}

.service-count-header {
    align-items: center;
    justify-content: space-between;
    text-align: center;
    margin-top: 40px;
    min-height: 36px;
}

.service-count-txt {
    margin-top: 25px;
    color: rgb(65, 65, 65);
    font-size: 36px;
    font-family: TelstraAkkurat-Bold;
    font-weight: bold;
    text-align: center;
    letter-spacing: -1px;
}
.total-services-txt {
    height: 8px;
    color: rgb(65, 65, 65);
    font-size: 16px;
    font-family: TelstraAkkurat-Regular;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 8px;
    text-align: center;
}

.active-devices-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "TelstraAkkurat";
    color: rgb(65, 65, 65);
    font-size: 16px;
    letter-spacing: 0.15px;
    min-width: 269px;
    border-bottom: 1px solid lightgray;
    height: 30px;
    padding: 0 1.25em 0.75em 0.25em;
    text-decoration: none;
}
.row-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "TelstraAkkurat";
    color: rgb(65, 65, 65);
    font-size: 16px;
    letter-spacing: 0.15px;
    min-width: 269px;
    border-bottom: 1px solid lightgray;
    height: 56px;
    padding: 0 1.25em 0 0.25em;
    margin-top: 21px;
    text-decoration: none;
}
