.message-box {
    margin: 2em 0;
    width: fit-content;
}

.info {
    margin-top: 35px;
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
}
.performance-cards-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5%;
}

.throughput-type-info {
    margin-top: 35px;
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 24px;
    font-weight: bold;
    letter-spacing: -0.1px;
}
