.legal-disclaimer-container {
    padding-bottom: 0;
    margin: 2em 0;
}

.legal-disclaimer-title {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
    margin-top: 0;
}

.legal-disclaimer-content {
    text-justify: inter-word !important;
    list-style-type: disc;
    font-family: "TelstraAkkuratLight";
    font-weight: 300;
    font-size: 14px;
    margin-top: 24px;
}