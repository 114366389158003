.dropdown-menu-container {
    width: 308px;
    height: auto;
    background-color: white;
    position: absolute;
    z-index: 99;
    border-radius: 5px;
    cursor: pointer;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
}

.dropdown-title {
    width: 308px;
    color: rgb(40, 40, 40);
    font-size: 14px;
    font-family: "TelstraAkkuratBold";
    font-weight: bold;
    letter-spacing: 0px;
    margin: 0;
    line-height: 22px;
}

.dropdown-description {
    width: 306px;
    color: rgb(112, 112, 112);
    font-size: 14px;
    font-family: "TelstraAkkuratRegular";
    font-weight: normal;
    letter-spacing: 0.18px;
    line-height: 22px;
}

.dropdown-box {
    width: 308px;
    height: 50px;
    color: rgb(65, 65, 65);
    font-size: 16px;
    font-family: "TelstraAkkuratRegular";
    font-weight: normal;
    border: 1px solid rgb(65, 65, 65);
    border-radius: 5px;
    margin: 10px 0 0 0;
    margin-right: 0px;
    padding: 15px 0 0 15px;
    cursor: pointer;
}

.dropdown-text {
    width: 250px;
    margin: 0;
    padding: 0;
    display: inline-block;
}

.chevron-style {
    display: inline-block;
}

.drop-down-item {
    width: auto;
    transition: var(--speed);
    padding: 10px 0;
    display: flex;
    margin: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.drop-down-item:hover {
    color: #1a64d2;
    cursor: pointer;
}

.filter-container {
    //float: right;
    margin: 20px 0 40px 0;
}

.dropdown-menu {
    width: 308px;
    margin: 0;
    padding: 0;
}

.loader-align-center {
    margin: auto;
    width: 30%;
    padding: 10px;
}
