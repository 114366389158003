.tooltip {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    max-width: 385px;
    max-height: 150px;
    margin: 0;
    padding: 16px;
    border-top: 2px solid;
    border-image: linear-gradient(90deg, #0064d2, #5cd6e0 60.56%, #5e50bf) 1;
    background-color: #fff;
    filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
    z-index: 1;
}

.tooltip-text {
    color: rgb(65, 65, 65);
    font-family: TelstraAkkurat-Regular;
    font-size: 14px;
    font-weight: normal;
    height: auto;
    letter-spacing: -0.2px;
    line-height: 20px;
    max-width: 380px;
    padding-bottom: 10px;
    padding-top: 25px;
}

.tooltip-close {
    float: right;
    background: none;
    border: 0px;
}

.show-tooltip {
    visibility: visible;
    opacity: 1;
}

.able-icon-button-overrides {
    height: auto;
}
