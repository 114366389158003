@font-face {
    font-family: "TelstraAkkurat";
    font-weight: normal;
    src: local("TelstraAkkurat"),
    url("../public/fonts/TelstraAkkurat-Regular.otf") format("opentype")
}

@font-face {
    font-family: "TelstraAkkuratRegular";
    font-weight: normal;
    src: local("TelstraAkkurat"),
    url("../public/fonts/TelstraAkkurat-Regular.otf") format("opentype")
}

@font-face {
    font-family: "TelstraAkkuratBold";
    font-weight: bold;
    src: local("TelstraAkkurat"),
    url("../public/fonts/TelstraAkkurat-Bold.otf") format("opentype")
}

@font-face {
    font-family: "TelstraAkkuratLight";
    font-weight: bold;
    src: local("TelstraAkkurat"),
    url("../public/fonts/TelstraAkkurat-Light.otf") format("opentype")
}

body {
    margin: 0;
    font-family: 'TelstraAkkurat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import "./common/common-styles.scss";
// @import "common/scss/skip-link-target.scss";
