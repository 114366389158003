.flex-dl {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;

    dt {
        font-family: "TelstraAkkuratBold";
    }

    dd {
        font-family: "TelstraAkkuratLight";
        //margin-left: 0;
    }
}

.service-status {
    height: 20px;
    color: rgb(65, 65, 65);
    font-size: 16px;
    font-family: TelstraAkkurat-Regular;
    font-weight: normal;
    letter-spacing: -0.23px;
    line-height: 20px;
}

.service-status-result {
    margin:0;
    height: 20px;
    color: rgb(117, 117, 117);
    font-size: 16px;
    font-family: TelstraAkkurat-Regular;
    font-weight: normal;
    letter-spacing: -0.23px;
    line-height: 20px;
}
.info-grouping {
    min-width: 14rem;

    &>dd {
        display: inline-block;
        position: relative;
        bottom: 6px;
    }

    & dt>svg {
        position: relative;
        top: 8px;
    }
}

.flex-dl-active-status {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width:430px;

    dt {
        font-family: "TelstraAkkuratBold";
    }

    dd {
        font-family: "TelstraAkkuratLight";
    }
}

.vl {
    margin-top: -5px;
    border-left: 1px solid rgb(151, 151, 151);
    height: 27px;
  }

.div-container {
    display:flex;

    & > dd {
        display: inline-flex;
        align-items: center;
    }

    & dt > svg {
        position: relative;
        top: 8px;
    }

    span{
        margin-left: 10px;
    }
}
