.info {
    margin-top: 35px;
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
}

.last-updated-info {
    margin-top: 8px;
    color: rgb(117, 117, 117);
    font-family: "TelstraAkkuratRegular";
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.2px;
}

.highest-availability {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
}

.lowest-availability {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    margin-top: 30px;
}

.period-info {
    left: 24px;
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratRegular";
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.2px;
    line-height: 20px;
    margin-left: 24px;
}

.availability-value {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratLight";
    font-size: 30px;
    font-weight: 300;
    letter-spacing: -0.96px;
}

.availability-content-detail {
    display: flex;
    flex-direction: row;
    margin-top: 46px;
    justify-content: flex-start;
}

.left-content {
    margin-bottom: 40px;
}