.able-MessageInline--Default > svg:first-of-type {
    display: none;
}

.preLinktxt {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratBold";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.1px;
    line-height: 20px;
    padding-right: 15px;
}

.inlineAnchor {
    text-decoration: none;
    font-size: 16px;
}

.linkTxt {
    color: rgb(0, 98, 218);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
    text-decoration: none;
    border-bottom: 2px solid rgb(0, 98, 218);
}

.linkWrapper > p {
    margin: 0;
}

.custom-svg-class {
    width: 24px;
    height: 24px;
    fill: rgb(0, 98, 218);
    margin-left: 5px;
    vertical-align: middle;

    use {
      fill: rgb(0, 98, 218);
    }
}
