.highest-throughput {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
}

.lowest-throughput {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    margin-top: 30px;
}

.period-info {
    left: 24px;
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratRegular";
    font-size: 14px;
    font-weight: normal;
    letter-spacing: -0.2px;
    line-height: 20px;
    margin-left: 24px;
    width: 146px;
}

.custom-svg-arrow-up {
    width: 24px;
    height: 24px;
    fill: rgb(9, 142, 9);
}

.custom-svg-arrow-down {
    width: 24px;
    height: 24px;
    fill: rgb(217, 58, 3);
}

.downlink-value {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratLight";
    font-size: 30px;
    font-weight: 300;
    letter-spacing: -0.96px;
    width: 146px;
    text-align: left;
}
