.summary-card {
    width: 328px;
    height: 590px;
    padding: 16px 23px 16px 23px;
    border-radius: 2px;
    border: 1px solid rgb(210, 210, 210);
    margin-bottom: 39px;
    min-width: 277px;

    &-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    &-container {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
    }

    &-header {
        display: flex;
        align-items: center;
    }

    &-title {
        color: rgb(40, 40, 40);
        font-family: "TelstraAkkuratBold";
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 44px;
        margin: 0;
    }

    &-subtitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 30px;

        &-wrapper {
            color: rgb(65, 65, 65);
            font-size: 16px;
            font-family: TelstraAkkurat-Regular;
            font-weight: normal;
            letter-spacing: 0.15px;
            line-height: 20px;
            margin: 0;
        }
    }

    &-loader {
        margin-top: 50%;
        text-align: center;
    }

    &-total-count {
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin-top: 40px;
        min-height: 36px;
    }

    &-total-count-title {
        align-items: center;
        justify-content: space-between;
        text-align: center;
        min-height: 19.5px;

        &-wrapper {
            height: 8px;
            color: rgb(65, 65, 65);
            font-size: 16px;
            font-family: TelstraAkkurat-Regular;
            font-weight: normal;
            letter-spacing: 0.15px;
            line-height: 8px;
            text-align: center;
        }
    }

    &-summary-table {
        border-collapse: collapse;
        margin-top: 1.75em;

        tr {
            th,
            td {
                color: rgb(65, 65, 65);
                font-weight: 500;
                border-bottom: 1px solid lightgray;
                padding: 1.8em 1.25em 0.75em 0.25em;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }
}

.action-btn {
    color: rgb(0, 100, 210);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.3px;
    line-height: 19px;
    min-width: 159px;
}
