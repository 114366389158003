.card {
    width: 328px;
    min-width: 277px;
}

.availability-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.availability-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.availability {
    color: rgb(40, 40, 40);
    font-family: "TelstraAkkuratBold";
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -1px;
    margin: 30px 0;
}

.average {
    color: rgb(65, 65, 65);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.15px;
    line-height: 22px;
}


.availability-btn {
    color: rgb(0, 100, 210);
    font-family: "TelstraAkkuratRegular";
    font-size: 16px;
    font-weight: normal;
    letter-spacing: -0.3px;
    line-height: 19px;
    min-width: 159px;
}